<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <h3 class="card-title">Profili Düzenle</h3>
        <div style="position: absolute; right: 10px" v-if="$store.state.user_type == 'EXPERT'">
          <button @click="openHelperVideo" class="btn" title="Bilgi edin"><i class="fa fa-info-circle"  style="font-size:25px;color: rgb(187, 116, 148);" ></i></button>
          <div
            class="modal fade"
            id="videoHelper"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <video controls>
                  <source
                    src="https://storage.terapivitrini.com/storage/profile/genel_bilgiler.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div
            class="col-sm-12 col-md-12"
            v-if="
              ($store.state.user_type == 'EXPERT' ||
                $store.state.user_type == 'CENTER') &&
              (pageInfo.approved_profile == 0 || pageInfo.approved_profile == 3)
            "
          >
            <button
              class="btn btn-danger w-100"
              id="approved_profile"
              @click="profileApproved"
            >
              Profilimi Onaya Gönder
            </button>
            <br />
            <br />
            <small style="font-size: 14px"
              >*Profil onaylama sürecine gönderilen profil üzerinde onay süresi
              3 iş günüdür.</small
            ><br /><br />
            <small style="font-size: 14px"
              >*İnceleme süreci boyunca yapmış olduğunuz değişiklikler
              kaydedilmeyecektir.
            </small>
            <br />
            <br />
            <small style="font-size: 14px">
              Onaya göndermeden önce
              <b>tüm bilgileri eksiksiz ve tam</b> girdiğinizden emin olun.
            </small>
          </div>
          <div class="col-sm-12 col-md-12" v-if="requests.length > 0">
            <b style="color:red; font-size:18px">Doldurulması gereken alanlar var!</b>
            <li
              v-for="(res, i) in requests"
              style="list-style: circle; font-weight: 500"
              :key="i"
            >
            
              <span v-html="res"></span>
            </li>
          </div>
          <div
            class="col-sm-12 col-md-12"
            v-if="$store.state.user_type == 'EXPERT' && pageInfo.approved_profile == 3"
          >
            <b>BİLGİLENDİRME:</b>{{ pageInfo.approved_profile_memo }}
          </div>
          <template v-if="pageInfo.billing_change_status == 1">
            <div class="text-center alert alert-warning">
              Abonelik talebiniz işleme alındı. İşlem tamamlandığında tarafınıza
              bildirilecektir.
            </div>
          </template>
          <div
            class="col-12 mt-5 mb-5"
            v-if="
              $store.state.user_type == 'EXPERT' &&
              pageInfo.billing_change_status == 0
            "
          >
            <div class="row">
              <div
                :class="
                  pageInfo.member_types.length == 1
                    ? 'col-xl-12 col-mt-12 col-mb-12'
                    : 'col-xl-6 col-mt-6 col-mb-6'
                "
                v-for="(member_type, i) in pageInfo.member_types"
                :key="'memberType' + i"
              >
                <div
                  :class="
                    'select_band_one' +
                    (selectedCard == member_type.id ? ' active_banner' : '')
                  "
                >
                  <h3>{{ member_type.title }}</h3>
                  <h4 v-if="member_type.price > 0">{{ member_type.price }}₺</h4>
                  <h4 v-if="member_type.price == 0">ÜCRETSİZ</h4>
                  <h6>
                    <p v-html="member_type.description"></p>
                  </h6>
                  <small
                    v-if="member_type.price > 0 && member_type.tax_include == 1"
                    >K.D.V. dahil</small
                  >
                  <button
                    class="btn btn-primary w-100"
                    @click="selected(member_type.id)"
                  >
                    <template v-if="selectedCard == member_type.id"
                      >Seçildi</template
                    >
                    <template v-else>Seç</template>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">Ad</label>
              <input
                name="fullname"
                type="text"
                :disabled="pageInfo.approved_profile == 1 ? true : false"
                :value="pageInfo.fullname"
                class="form-control"
                placeholder="İsim ve soyisminizi giriniz"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12">
            <div class="form-group">
              <label class="form-label">E-Posta</label>
              <input
                type="email"
                name="email"
                disabled
                :value="pageInfo.email"
                class="form-control"
                placeholder="e-posta"
              />
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12"
            v-if="$store.state.user_type == 'EXPERT'"
          >
            <div class="form-group">
              <label class="form-label">Ünvan</label>
              <input
                type="expertiance"
                name="expertiance"
                v-model="pageInfo.expert"
                class="form-control"
                placeholder="Ünvan Girin"
              />
            </div>
          </div>
          <div
            class="col-sm-12 col-md-12"
            v-if="$store.state.user_type == 'EXPERT'"
          >
            <div class="form-group">
              <label class="form-label">Randevu İptal Süresi (Saat)</label>
              <input
                type="expertiance"
                name="expertiance"
                v-model="pageInfo.appointment_cancel_hour"
                class="form-control"
                placeholder="Randevu İptal Süresi Girin"
              />
              <small>*Alınan randevuların iptal edilebileceği süre</small>
            </div>
          </div>

          <div
            class="col-sm-12 col-md-12"
            v-if="$store.state.user_type == 'EXPERT'"
          >
            <label class="form-label">Terapist Kimlik Doğrulama</label>
            <div
              class="form-group"
              style="
                border: 1px solid #e9ebf3;
                border-radius: 3px;
                padding: 7px;
              "
            >
              <div class="row">
                <div class="col-sm-7 col-md-7 mb-2">
                  Sertifika, eğitim belgesi veya lisans diploması yüklenmesi gerekmektedir.
                </div>
                <div
                  class="col-sm-5 col-md-5"
                  style="text-align: end"
                  v-if="$store.state.user_type == 'EXPERT'"
                >
                  <a
                    @click="certificateUpload(0)"
                    title="Sertifika Yükle"
                    class="btn btn-primary btn-sm"
                    style="margin-left: 10px"
                    >Belge Seç <i class="fa fa-file-pdf-o"></i
                  ></a>
                  <a
                    title="Sertifika Görüntüle"
                    :href="
                      'https://storage.terapivitrini.com/' +
                      pageInfo.approved_file
                    "
                    class="btn btn-warning btn-sm"
                    target="_blank"
                    v-if="pageInfo.approved_file != null"
                    style="margin-left: 10px"
                    ><i class="fa fa-search"></i
                  ></a>
                  <input
                    type="file"
                    :id="'certificateUpload' + 0"
                    :name="'certificateUpload' + 0"
                    @change="certificateUploadChange(0)"
                    accept="application/pdf,image/jpeg, image/png"
                    style="display: none"
                  />
                  <a
                    @click="certificateFileUpload(0, $store.state.id)"
                    title="Sertifika Yükle"
                    class="btn btn-warning btn-sm"
                    v-if="certificateUploadList[0] != null"
                    style="margin-left: 10px"
                    >Yüklemeyi Başlat
                    <i class="fa fa-upload" v-if="!uploadingCertificate[0]"></i>
                    <div
                      v-if="uploadingCertificate[0]"
                      class="spinner-border"
                      role="status"
                      style="width: 15px; height: 15px"
                    >
                      <span class="sr-only">Loading...</span>
                    </div></a
                  ><br />
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">Facebook</label>
              <input
                type="text"
                name="facebook"
                v-model="pageInfo.facebook"
                class="form-control"
                placeholder="https://www.facebook.com/"
              />
            </div>
          </div>
          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">Instagram</label>
              <input
                type="text"
                name="instagram"
                class="form-control"
                v-model="pageInfo.instagram"
                placeholder="https://www.instagram.com/"
              />
            </div>
          </div>
          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">Twitter</label>
              <input
                type="text"
                name="twitter"
                v-model="pageInfo.twitter"
                class="form-control"
                placeholder="https://twitter.com/"
              />
            </div>
          </div>
          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">YouTube</label>
              <input
                type="text"
                name="youtube"
                v-model="pageInfo.youtube"
                class="form-control"
                placeholder="https://youtube.com/"
              />
            </div>
          </div>
          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">LinkedIn</label>
              <input
                type="text"
                name="linkedin"
                v-model="pageInfo.linkedin"
                class="form-control"
                placeholder="https://linkedin.com/"
              />
            </div>
          </div>

          <div
            class="col-sm-6 col-md-6"
            v-if="$store.state.user_type == 'CENTER'"
          >
            <div class="form-group">
              <label class="form-label">Web Sitesi</label>
              <input
                type="text"
                name="website"
                v-model="pageInfo.website"
                class="form-control"
                placeholder="https://sizinsiteniz.com/"
              />
            </div>
          </div>
          <div
            v-if="
              $store.state.user_type == 'CENTER' &&
              pageInfo.expert_list.length > 0
            "
            class="col-sm-12 col-md-12 col-xl-12"
          >
            <label class="form-label">Kadro</label>
            <div class="col-12">
              <div
                class="row itemcard"
                v-for="(item, i) in pageInfo.expert_list"
                :key="i"
              >
                <div class="col-10">
                  <span>{{ item.fullname }}</span>
                </div>
                <div class="col-2">
                  <div class="input-group">
                    <button
                      class="btn btn-primary"
                      @click="removeCenterExpert(item.user_id)"
                    >
                      <i class="fa fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="$store.state.user_type == 'EXPERT'"
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="appointment_status"
              name="appointment_status"
              :checked="appointmentStatus == 1 ? true : false"
              @click="appointmentStatusChange"
            />
            <label class="form-check-label" for="appointment_status"
              >Randevu sistemini<template v-if="appointmentStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="appointmentStatus == 0">
                <b> AÇ</b></template
              ></label
            >
          </div>
          <div
            v-if="$store.state.user_type == 'EXPERT'"
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="sms_status"
              name="sms_status"
              :checked="smsStatus == 1 ? true : false"
              @click="smsStatusChange"
            />
            <label class="form-check-label" for="sms_status"
              >Sms Bildirim Sistemi<template v-if="smsStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="smsStatus == 0"> <b> AÇ</b></template></label
            >
          </div>
          <div
            v-if="$store.state.user_type == 'EXPERT'"
            class="col-sm-12 col-md-12 col-xl-4 form-check form-switch"
            style="padding-left: 50px"
          >
            <input
              class="form-check-input"
              type="checkbox"
              id="mail_status"
              name="mail_status"
              :checked="mailStatus == 1 ? true : false"
              @click="mailStatusChange"
            />
            <label class="form-check-label" for="mail_status"
              >Mail Bildirim Sistemi<template v-if="mailStatus == 1">
                <b> KAPAT</b></template
              >
              <template v-if="mailStatus == 0"> <b> AÇ</b></template></label
            >
          </div>

          <div
            class="col-sm-12 col-md-12"
            v-if="
              $store.state.user_type == 'EXPERT' &&
              $store.state.approved_profile
            "
          ></div>
        </div>
      </div>
      <div class="card-footer" style="padding: 10px">
        <div style="float: left">
          <button
            type="button"
            id="saveButton"
            @click="save"
            class="btn btn-primary btn-sm"
          >
            Profili Güncelle
          </button>
        </div>
        <div style="float: right" v-if="$store.state.user_type == 'EXPERT'">
          <input
            type="button"
            name="expertiance"
            value="Üyeliği Sil"
            @click="remove"
            style="margin-right: 10px"
            class="btn btn-primary btn-sm"
            placeholder="Terapi Girin"
          />
          <input
            type="button"
            name="expertiance"
            @click="cancel"
            value="Aboneliği İptal Et"
            class="btn btn-primary btn-sm"
            placeholder="Terapi Girin"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.itemcard {
  margin-top: 10px;
  box-shadow: 0px 0px 5px 0px #d6d6d6;
  padding: 5px;
  border-radius: 10px;
  line-height: 38px;
  border-left: 4px solid #bb7494;
}
.select_band_one {
  border: 1px solid #dddddd;
  background-color: #fff;
  padding: 10px;
  text-align: center;
  border-radius: 5px;

  transition: all cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.active_banner {
  background: rgb(143 74 106 / 22%);
}
.select_band_one:hover {
  cursor: pointer;
  transition: all 0.5s;
}
</style>
<script>
import Swal from "sweetalert2";
export default {
  created() {
    document.title = "Profil Güncelle";
    this.$store.dispatch("profileGet", { type: 1 }).then((value) => {
      this.pageInfo = value[0];
      this.selectedCard = value[0].selected_billing_type;
      this.appointmentStatus = value[0].appointment_status;
      this.mailStatus = value[0].mail_status;
      this.smsStatus = value[0].sms_status;
    });
  },
  data() {
    return {
      pageInfo: [],
      selectedCard: 0,
      appointmentStatus: 0,
      mailStatus: 0,
      smsStatus: 0,
      certificateList: [],
      certificateUploadList: [],
      uploadingCertificate: [],
      requests: [],
    };
  },
  methods: {
    openHelperVideo() {
      $("#videoHelper").modal("show");
    },
    removeCenterExpert(i) {
      Swal.fire({
        title: "Uzman kurumdan çıkarılacak, devam edilsin mi?",
        text: "Bu işlem uzmanı kurumdan çıkarır. Devam etmek istiyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Kurumdan Çıkar",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store
            .dispatch("removeCenterExpert", { user_id: i })
            .then((value) => {
              this.$vToastify.success(
                "Uzman başarılı bir şekilde silindi!",
                "Başarılı!"
              );
              this.pageInfo.expert_list = value;
            });
        }
      });
    },
    certificateUpload(i) {
      $("#certificateUpload" + i).trigger("click");
    },
    certificateFileUpload(i, id) {
      this.uploadingCertificate[i] = true;
      this.uploadingCertificate.push({});
      this.uploadingCertificate.pop();
      let file = this.certificateUploadList[i];

      let formData = new FormData();
      formData.append("file", file);
      formData.append("id", id);
      this.$store
        .dispatch("userUploadApprovedFile", formData)
        .then((response) => {
          this.$vToastify.success(
            "Dosya başarılı bir şekilde yüklendi",
            "Başarılı!"
          );
          this.uploadingCertificate[i] = false;
          this.uploadingCertificate.push({});
          this.uploadingCertificate.pop();

          this.certificateUploadList[i] = null;
          this.certificateUploadList.push({});
          this.certificateUploadList.pop();

          this.pageInfo.approved_file = response;
        });
    },
    certificateUploadChange(i) {
      let file = document.querySelector(
        "input[name=certificateUpload" + i + "]"
      ).files[0];
      if (
        file.type == "application/pdf" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        this.certificateUploadList[i] = file;
        this.certificateUploadList.push({});
        this.certificateUploadList.pop();
      } else {
        this.$vToastify.warning(
          "Sadece PDF,JPG ve PNG formatında dosyalar!",
          "Uyarı!"
        );
      }
    },
    certificateUploadFile(i) {
      let file = document.querySelector(
        "input[name=certificateUpload" + i + "]"
      ).files[0];
      if (file.type == "application/pdf") {
        this.certificateUploadList[i] = file;
        this.certificateUploadList.push({});
        this.certificateUploadList.pop();
      } else {
        this.$vToastify.warning("Sadece PDF formatında dosyalar!", "Uyarı!");
      }
    },
    appointmentStatusChange() {
      if (this.appointmentStatus == 0) {
        this.appointmentStatus = 1;
      } else {
        this.appointmentStatus = 0;
      }
    },
    mailStatusChange() {
      if (this.mailStatus == 0) {
        this.mailStatus = 1;
      } else {
        this.mailStatus = 0;
      }
    },
    smsStatusChange() {
      if (this.smsStatus == 0) {
        this.smsStatus = 1;
      } else {
        this.smsStatus = 0;
      }
    },
    profileApproved() {
      this.$store.dispatch("profileApproved").then((value) => {
        if (value.type == "success") {
          this.$vToastify.success(
            "Profiliniz onay sürecine gönderildi",
            "Başarılı!"
          );
          document.getElementById("approved_profile").disabled = true;
          document.getElementById("approved_profile").innerHTML =
            "Profiliniz Onaya Gönderildi";
        } else {
          this.$vToastify.warning("Profilinizde eksik bilgileriniz var!", "Uyarı!",2000);
          document.getElementById("approved_profile").disabled = false;
          document.getElementById("approved_profile").innerHTML =
            "Profilimi Onaya Gönder";
          this.requests = value.requests;
        }
      });
    },
    selected(item) {
      this.selectedCard = item;
    },
    cancel() {
      Swal.fire({
        title: "Abonelik iptal edilsin mi?",
        text: "Bu işlem aboneliğinizi sonlandırır ve güncel faturanız (varsa) oluşturur. Devam etmek istiyor musunuz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Abonelik İptal",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("subscribeCancel").then((value) => {
            if (value.type == "success") {
              this.$vToastify.success(
                "Abonelik başarılı bir şekilde iptal edildi!",
                "Başarılı!"
              );

              this.$store.dispatch("profileGet", { type: 1 }).then((value) => {
                this.pageInfo = value[0];
                this.selectedCard = value[0].selected_billing_type;
                this.appointmentStatus = value[0].appointment_status;
              });
            }
          });
        }
      });
    },
    remove() {
      Swal.fire({
        title: "Üyeliğiniz silinsin mi?",
        text: "Bu işlem üyeliğinizi sonlandırır ve profil verilerinizi sıfırlar. Devam etmek istiyor musunuz?",
        icon: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Üyelik Sil",
        cancelButtonText: "Vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("memberRemove").then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRx001") {
                this.$vToastify.warning(
                  "Devam eden abonelik iptal edilmeden üyelik silinemez!",
                  "Uyarı!"
                );
              }
              if (value.message == "ERRx002") {
                this.$vToastify.warning(
                  "Ödenmemiş faturalarınız varken üyelik silinemez!",
                  "Uyarı!"
                );
              }
            }
            if (value.type == "success") {
              this.$vToastify.success(
                "Üyeliğiniz başarılı bir şekilde silindi",
                "Başarılı!"
              );
              this.$store.dispatch("logout");
            }
          });
        }
      });
    },
    save() {
      let expertiance = "";
      if (this.$store.state.user_type == "EXPERT") {
        expertiance = document.querySelector("input[name=expertiance]").value;
      }
      let fullname = document.querySelector("input[name=fullname]").value;
      if (fullname == null || fullname == "") {
        this.$vToastify.warning("Tam isim boş geçilemez", "Uyarı!");
      } else if (
        (expertiance == null || expertiance == "") &&
        this.$store.state.user_type == "EXPERT"
      ) {
        this.$vToastify.warning("Terapi alanı boş geçilemez", "Uyarı!");
      } else {
        document.getElementById("saveButton").disabled = true;
        document.getElementById("saveButton").innerHTML =
          "Bilgiler kayıt ediliyor..";
        let profileSave = {
          fullname: fullname,
          facebook: this.pageInfo.facebook,
          instagram: this.pageInfo.instagram,
          youtube: this.pageInfo.youtube,
          twitter: this.pageInfo.twitter,
          linkedin: this.pageInfo.linkedin,
          website: this.pageInfo.website,
          expert: expertiance,
          selected_billing_type: this.selectedCard,
          appointment_status: this.appointmentStatus ? 1 : 0,
          mail_status: this.mailStatus ? 1 : 0,
          sms_status: this.smsStatus ? 1 : 0,
          appointment_cancel_hour: this.pageInfo.appointment_cancel_hour,
        };
        this.$store.dispatch("profileSave", profileSave).then((value) => {
          document.getElementById("saveButton").disabled = false;
          document.getElementById("saveButton").innerHTML = "Profili Güncelle";
          if (value.type == "success") {
            this.$vToastify.success("Profil güncelleme başarılı", "Başarılı!");

            this.$store.dispatch("profileGet").then((value) => {
              this.pageInfo = value[0];
              this.selectedCard = value[0].selected_billing_type;
              this.appointmentStatus = value[0].appointment_status;
            });
          } else if (value.type == "error") {
            if (value.message == "ERRxDBE") {
              this.$vToastify.warning("Kayıt sırasında hata oluştu", "Uyarı!");
            }
            if (value.message == "ERRxUAR") {
              this.$vToastify.warning(
                "Onay sürecindeki profil güncellenemez",
                "Uyarı!"
              );
            }
          }
        });
      }
    },
  },
  components: {},
  mounted() {},
};
</script>